import React from 'react';
import PurpleWitchesPaddleEvent from '../assets/images/events/PurpleCutePetHalloweenPartyInvitationPoster.png';
import New_Year_Beach_Walk from '../assets/images/events/New_Year_Beach_Walk.png';

const events = [
    
    {
        id: 2,
        title: 'New Year\'s Beach Walk',
        date: 'January 4th, 2025',
        time: '10:30 AM - 12:30 PM',
        location: 'End of Grand Ave, Grover Beach',
        image: New_Year_Beach_Walk,
        url: 'https://www.facebook.com/events/1211900743235292/',
        notes: 'Join us for a New Year\'s Beach Walk to celebrate the start of 2025. We will meet at the end of Grand Ave in Grover Beach and walk along the beach. Dogs are welcome, but must be on a leash. Free snacks and beverages will be provided after the walk!'
    },
    {
        id: 1,
        title: 'Witches and Warlocks Paddle',
        date: 'October 26th, 2024',
        time: '12:00 PM - 4:00 PM',
        location: 'Coleman Park, Morro Bay',
        image: PurpleWitchesPaddleEvent,
    },
];

function Events() {
    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>Upcoming Events</h2>
            <div style={styles.eventList}>
                {events.map((event) => (
                    <div key={event.id} style={styles.eventItem}>
                        <img src={event.image} alt={event.title} style={styles.eventImage} />
                        <h3 style={styles.eventTitle}>{event.title}</h3>
                        <p style={styles.eventDate}>{event.date}</p>
                        {event.time && <p style={styles.eventTime}><strong>Time:</strong> {event.time}</p>}
                        {event.location && <p style={styles.eventLocation}><strong>Location:</strong> {event.location}</p>}
                        {event.url && <p style={styles.eventLocation}><a href = {event.url}>Event Link</a></p>}
                        {event.notes && <p style={styles.eventLocation}>{event.notes}</p>}
                    </div>
                ))}
            </div>
        </div>
    );
}

const styles = {
    container: {
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    eventList: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        maxHeight: '600px',
        paddingRight: '10px',
    },
    eventItem: {
        marginBottom: '20px',
        borderBottom: '1px solid #ccc',
        paddingBottom: '10px',
    },
    eventImage: {
        width: '100%',
        maxWidth: '600px',
        height: 'auto',
        display: 'block',
        margin: '0 auto',
    },
    eventTitle: {
        fontSize: '1.5rem',
        textAlign: 'center',
        marginTop: '10px',
    },
    eventDate: {
        fontSize: '1.2rem',
        textAlign: 'center',
    },
    eventTime: {
        fontSize: '1rem',
        textAlign: 'center',
    },
    eventLocation: {
        fontSize: '1rem',
        textAlign: 'center',
    },
};

export default Events;
