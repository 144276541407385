import React from 'react';
// import sky_img from '../assets/images/sky_profile_img.png';
import chloe_img from '../assets/images/chloe_profile_img.png';
import val_img from '../assets/images/val_profile_img.png';
import sky_img from "../assets/images/pp_home_img.png";
import './About.css'; // Import the CSS file

function About() {
    return (
        <div className="container">
            <h2>About Us</h2>
            <p>
                Paddling Paws is dedicated to helping rescue, rehabilitate, and rehome animals in need. We strive to find fresh ways to make a difference in the lives of animals and the people who love them. Committed to founding new initiatives, we are always looking for new ways to help animals in need and celebrating the coast we call home here in San Luis Obispo, California. Join us in our mission to help animals find their forever homes.
            </p>

            {/* Sky's Profile */}
            <div className="profileContainer">
                <div className="imageContainer">
                    <img src={sky_img} alt="Meet Sky, our founder on our rescue nonprofit tea" className="image" />
                </div>
                <div className="textContainer">
                    {/* <h2>Sky</h2> */}
                    <p>
                    <strong>Hi, I’m Sky, and I’m deeply passionate about rescuing and rehabilitating dogs and cats in California!</strong> After volunteering and fostering with local rescues on the Central Coast, I saw firsthand the incredible impact these organizations have on the lives of homeless pets. I founded Paddling Paws to take my dedication to the next level, using my experience and resources to create a safe haven for animals in need.
                    <br/><br/>
                    With a degree from MIT in 2021 and a career in tech and AI, I’m uniquely positioned to bring innovative solutions to the rescue world. I combine my technical background with a deep love for animals, aiming to not only save lives but also improve the efficiency of rescue operations. My goal is to leverage these skills, along with a network of compassionate supporters, to maximize the number of pets we can save and rehome.
                    <br/><br/>
                    At Paddling Paws, our mission is clear: every animal deserves a chance to live a happy, healthy life in a loving home. Together, we can make that happen—one rescue at a time.
                    </p>
                </div>
            </div>

            {/* Chloe's Profile */}
            <div className="profileContainer">
                <div className="imageContainer">
                    <img src={chloe_img} alt="Meet Chloe, a member on our rescue nonprofit team" className="image" />
                </div>
                <div className="textContainer">
                    {/* <h2>Chloe</h2> */}
                    <p><strong>Hi, I’m Chloe, and I’ve called the Central Coast my home for as long as I can remember.</strong> From an early age, I’ve had a deep love for animals, especially rescue dogs and cats. My own dog, Borski, was a rescue, and through him, I’ve come to realize just how many incredible animals are still out there waiting for their forever homes.
                    <br/><br/>
                    As Vice President of Paddling Paws, I’m thrilled to help drive our mission forward, focusing on fundraising and spreading awareness about the critical work we do. Whether it’s hosting events or building partnerships, I’m committed to giving every dog and cat the second chance they deserve.
                    <br/><br/>
                    Rescue work is a team effort, and I’m so excited to be part of a community that shares my passion for saving lives and creating happier futures for pets in need.</p>
                </div>
            </div>

            {/* Val's Profile */}
            <div className="profileContainer">
                <div className="imageContainer">
                    <img src={val_img} alt="Meet Val, a member on our rescue nonprofit tea" className="image" />
                </div>
                <div className="textContainer">
                    {/* <h2>Val</h2> */}
                    <p><strong>Hi, I’m Val, and I’m incredibly excited to be part of Paddling Paws!</strong> I’ve been dedicated to animal welfare on the Central Coast for years, with a special focus on rescuing and caring for cats. Since 2018, I’ve been actively involved in TNR (trap-neuter-return) efforts, helping to control the stray cat population in Nipomo, where I also manage a long-standing cat colony.
                    <br/><br/>
                    For nearly a decade, I’ve worked tirelessly to find homes or placements for these cats, whether it’s adopting them out or connecting them with local farms where they can live safely. It’s been a rewarding journey, and I’m thrilled to bring my experience and passion to Paddling Paws as Secretary, where we can continue to make a lasting impact on the lives of animals in need.</p>
                </div>
            </div>
        </div>
    );
}

export default About;
