import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Animals from './pages/Animals';
import Foster from './pages/Foster';
// import Interest from './pages/Interest';
import Donate from './pages/Donate';
import Events from './pages/Events';
import Sponsors from './pages/Sponsors';
import Wine from './pages/Wine';
import Adopt from './pages/Adopt';

function App() {
    return (
        <Router>
            <div className="app-container">
                <Header />
                <main className="main-content">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/animals" element={<Animals />} />
                        <Route path="/adopt" element={<Adopt />} />
                        <Route path="/foster" element={<Foster />} />
                        {/* <Route path="/interest" element={<Interest />} /> */}
                        <Route path="/donate" element={<Donate />} />
                        <Route path="/events" element={<Events />} />
                        <Route path="/sponsors" element={<Sponsors />} />
                        <Route path="/wine" element={<Wine />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
